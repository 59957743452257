import React from 'react';
import Somos from '../images/SomosNatural/Somos.jpg'
import './styles/WeAreNatural.scss';

const WeAreNatural = () => {
    return (
        <div className="allC">
            <div className="Somos">
                <img src={Somos} alt="Somos Natural" />
            </div>
            
        </div>

    )
}

export default WeAreNatural